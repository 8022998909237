import React, { Component } from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import { reactiveTemplateConfigSelector } from 'common/selectors/theme';
const ContactElementVariant = loadable(props => import(`./${props.blockVariant}`));

class Contact extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { t, theme = null, title, subtitle, blockSettings, ...rest } = this.props;
    if (!_.get(blockSettings, 'blockVariant')) {
      return null;
    }
    return (
      <ContactElementVariant
        theme={theme}
        blockVariant={_.get(blockSettings, 'blockVariant')}
        title={title}
        subtitle={subtitle}
        t={t}
        {...rest}
      />
    )
  }
}

const makeMapStateToProps = () => {
  const reactiveTemplateConfig = reactiveTemplateConfigSelector('ContactElementVariant');
  return (state, props) => {
    return {
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};

const ContactConnected = connect(makeMapStateToProps)(Contact);

export default withTranslation()(withTheme(ContactConnected));
